<template>
  <div class="GroupInvitationItem">
    <div><b>GroupInvitationItem</b> {{ groupInvitation.id }}</div>
    <br>
    <div>email: {{ groupInvitation.email }}</div>
    <!-- <div>invitation url: <input :value="invitationUrl" readonly ref="url"></div> -->
    <div>
      invitation url: <span style="user-select: all;">{{ invitationUrl }}</span>
    </div>

    <br>
    <div>
      <Button
        label="Revoke"
        @click="deleteInvitation()" />
            &nbsp;
      <Button
        label="Url to clipboard"
        @click="urlToClipboard()" />
    </div>
  </div>
</template>

<script>
import { copyStringToClipboard } from '@/utils';
import Button from './Button';

export default {
    components: { Button },
    props: {
        groupInvitation: {
            type: Object,
            required: true
        }
    },
    computed: {
        invitationUrl() {
            return `${location.origin}/social/group/${this.groupInvitation.groupId}/invitation/${this.groupInvitation.id}`;
        }
    },
    methods: {
        deleteInvitation() {
            this.$store.dispatch('moduleSocial/deleteGroupInvitation', { groupInvitationId: this.groupInvitation.id });
        },
        urlToClipboard() {
            copyStringToClipboard(this.invitationUrl);
        }
    }
};
</script>

<style scoped lang="scss">
.GroupInvitationItem {
    position: relative;
    padding: 1em;
    border: 1px solid rgba(black, 0.1);
    border-radius: 0.5em;
}
</style>
