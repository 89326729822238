<template>
  <div class="MemogeniusSingleSessionSettings">
    <div
      v-if="!selectedGameItem"
      class="games">
      <div
        v-for="gameItem of gameItems"
        :key="gameItem.id"
        class="GameItem"
        :style="{ backgroundImage: `url(${gameItem.image})` }"
        @click="pickGame(gameItem)" />
    </div>
    <div v-else>
      <div
        class="GameItem"
        :style="{ backgroundImage: `url(${selectedGameItem.image})` }" />
      <div @click="pickGame(null)">
        select another game
      </div>
      <br>
    </div>

    <div v-if="selectedGameItem">
      <!-- Select level: <input type="number" max="11" min="0" v-model="levelIndex"> -->
      <div
        v-for="(option, i) of selectedGameItem.challengePresets"
        :key="i">
        <input
          :id="'x' + i"
          v-model="pickedOption"
          type="radio"
          :value="option">
        <label :for="'x' + i">{{ option.i18n.label }}</label>
      </div>
    </div>
  </div>
</template>

<script>
const images = require.context('@/assets/images/memogenius/game/icon/', true, /\.(png|jpg)$/);

export default {
    data() {
        return {
            selectedGameItem: null,
            levelIndex: 0,
            pickedOption: null
        };
    },
    computed: {
        gameItems() {
            const gameIds = this.$store.getters['moduleMemogenius/getGameIds'].filter(
                gameId => require('@/modules/game-' + gameId + '/theme').default.hasChallenge
            );

            return gameIds.map(gameId => {
                return {
                    id: gameId,
                    image: images(`./${gameId}.png`),
                    challengePresets: require('@/modules/game-' + gameId + '/challenge-presets').default
                };
            });
        },
        valid() {
            return !!this.selectedGameItem && !!this.pickedOption;
        }
    },
    methods: {
        pickGame(gameItem) {
            this.selectedGameItem = gameItem;
            this.pickedOption = null;
        },
        getSettings() {
            return {
                gameId: this.selectedGameItem.id,
                sessionOptions: this.pickedOption.sessionOptions
            };
        }
    }
};
</script>

<style scoped lang="scss">
.MemogeniusSingleSessionSettings {
    padding: 1em;
    border: 1px solid rgba(black, 0.1);
    border-radius: 0.5em;
}
.games {
    display: flex;
    flex-wrap: wrap;
}
.GameItem {
    width: 3em;
    height: 3em;
    // border: 1px solid red;

    margin: 0.5em;

    background-size: contain;
}
</style>
