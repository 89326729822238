<template>
  <div class="GroupPanel">
    <div><b>Group</b> {{ group.id }}</div>
    <br>
    <div
      class="accessTag"
      :class="group.private ? 'accessTag--private' : 'accessTag--public'">
      {{ group.private ? 'private' : 'public' }}
    </div>

    <div>name: {{ group.name }}</div>
    <br>
    <div>
      <Button
        class="button"
        label="Delete"
        @click="deleteGroup()" />
            &nbsp;
      <Button
        class="button"
        label="Invite to this group"
        @click="invite()" />
            &nbsp;
      <Button
        class="button"
        label="Create challenge"
        @click="createChallenge()" />
            &nbsp;
      <Button
        class="button"
        label="Rename"
        @click="rename()" />
    </div>
    <!-- <h2>members:</h2> -->
    <div>
      <MemberItem
        v-for="(member, i) of group.membersByGroupIdList"
        :key="i"
        :member="member" />
    </div>

    <!-- <h2>challenges:</h2> -->
    <div>
      <ChallengeItem
        v-for="(challenge, i) of group.challengesByGroupIdList"
        :key="i"
        :challenge="challenge"
        @click.native="gotoChallengeView(challenge.id)" />
    </div>

    <div>
      <GroupInvitationItem
        v-for="groupInvitation of group.groupInvitationsByGroupIdList"
        :key="groupInvitation.id"
        :group-invitation="groupInvitation" />
    </div>
  </div>
</template>

<script>
import MemberItem from './MemberItem';
import ChallengeItem from './ChallengeItem';
import Button from './Button';
import CreateChallengePopup from './CreateChallengePopup';
import CreateGroupInvitationPopup from './CreateGroupInvitationPopup';
import GroupInvitationItem from './GroupInvitationItem';

export default {
    components: { MemberItem, ChallengeItem, Button, GroupInvitationItem },
    props: {
        group: {
            type: Object,
            required: true
        }
    },
    methods: {
        deleteGroup() {
            this.$store.dispatch('moduleSocial/deleteGroup', { groupId: this.group.id });
        },
        rename() {
            const newGroupName = prompt('Enter new group name', this.group.name);
            if (newGroupName && newGroupName !== this.group.name) {
                this.$store.dispatch('moduleSocial/renameGroup', { groupId: this.group.id, name: newGroupName });
            }
        },
        async invite() {
            const result = await this.$modal
                .open({
                    component: CreateGroupInvitationPopup,
                    target: 'social-modal'
                })
                .waitDone();
            if (result === 'cancel') {
                return;
            }
            // 'user2@foobar.com'
            this.$store.dispatch('moduleSocial/createGroupInvitation', { groupId: this.group.id, email: result.email });
        },
        async createChallenge() {
            const result = await this.$modal
                .open({
                    component: CreateChallengePopup,
                    target: 'social-modal'
                })
                .waitDone();

            if (result === 'cancel') {
                return;
            }

            const payload = Object.assign({ groupId: this.group.id, challengeText: 'some text' }, result);
            this.$store.dispatch('moduleSocial/createChallenge', payload);
        },
        gotoChallengeView(challengeId) {
            this.$router.push(`/social/group/${this.group.id}/challenge/${challengeId}`);
        }
    }
};
</script>

<style scoped lang="scss">
.GroupPanel {
    position: relative;
    padding: 1em;
    border: 1px solid rgba(black, 0.1);
    border-radius: 0.5em;
}
.MemberItem,
.ChallengeItem,
.GroupInvitationItem {
    margin-top: 1em;
}

.accessTag {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    padding: 0.5em 1em;
    border-radius: 2em;
    font-size: 90%;
}
.accessTag--private {
    background-color: #7d398e;
    color: white;
}
.accessTag--public {
    background-color: #528e39;
    color: white;
}
</style>
