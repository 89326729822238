<template>
  <div class="FoobarSettings">
    FoobarSettings
    <!-- <div v-if="!selectedGameItem" class="games">
      <div class="GameItem" :style="{ backgroundImage: `url(${gameItem.image})` }" v-for="gameItem of gameItems" :key="gameItem.id" @click="pickGame(gameItem)">{{ gameItem.id }}</div>
    </div>
    <div v-else>
      <div class="GameItem" :style="{ backgroundImage: `url(${selectedGameItem.image})` }">{{ selectedGameItem.id }}</div>
      <div @click="pickGame(null)">select another game</div>
    </div>

    <div v-if="selectedGameItem">
      Select level: <input type="number" max="11" min="0" v-model="levelIndex">
    </div> -->
  </div>
</template>

<script>
// const images = require.context('@/assets/images/memogenius/game/icon/', true, /\.(png|jpg)$/)

export default {
    data() {
        return {
            valid: true
        };
    },
    computed: {
        // gameItems () {
        //   return this.$store.getters['moduleMemogenius/getGameIds'].map(gameId => {
        //     return {
        //       id: gameId,
        //       image: images(`./${gameId}.png`)
        //     }
        //   })
        // },
        // valid () {
        //   return !!this.selectedGameItem
        // }
    },
    methods: {
        // pickGame (gameItem) {
        //   this.selectedGameItem = gameItem
        // },
        getSettings() {
            return {
                // gameId: this.selectedGameItem.id,
                // levelIndex: this.levelIndex
            };
        }
    }
};
</script>

<style scoped lang="scss">
.FoobarSettings {
    padding: 1em;
    border: 1px solid rgba(black, 0.1);
    border-radius: 0.5em;
}
</style>
