<template>
  <div class="CreateChallengePopup">
    <div>CreateChallengePopup</div>
    <br>

    <div>
      challenge type:
      <select v-model="challengeType">
        <option
          v-for="option in challengeTypes"
          :key="option"
          :value="option">
          {{ option }}
        </option>
      </select>
    </div>

    <br>
    <component
      :is="settingsComp"
      ref="settingsComp" />
    <br>
    <div>
      <Button
        label="Cancel"
        @click="$emit('signal', 'cancel')" />
            &nbsp;
      <Button
        label="Create"
        :disabled="!valid"
        @click="create()" />
    </div>
  </div>
</template>

<script>
import Button from './Button';

import SingleGameLevelSettings from './challenge-settings/SingleGameLevelSettings';
import FoobarSettings from './challenge-settings/FoobarSettings';
import MemogeniusSingleSessionSettings from './challenge-settings/MemogeniusSingleSessionSettings';

export default {
    components: { Button },
    data() {
        return {
            challengeTypes: ['memogeniusSingleSession', 'singleGameLevel', 'foobar'],
            challengeType: 'singleGameLevel',
            valid: false
        };
    },
    computed: {
        settingsComp() {
            return {
                singleGameLevel: SingleGameLevelSettings,
                foobar: FoobarSettings,
                memogeniusSingleSession: MemogeniusSingleSessionSettings
            }[this.challengeType];
        }
    },
    watch: {
        challengeType: {
            handler() {
                this.$nextTick(() => {
                    this.$watch(
                        () => this.$refs.settingsComp.valid,
                        value => {
                            this.valid = value;
                        },
                        { immediate: true }
                    );
                });
            },
            immediate: true
        }
    },
    methods: {
        create() {
            this.$emit('signal', {
                challengeType: this.challengeType,
                settings: this.$refs.settingsComp.getSettings()
            });
        }
    }
};
</script>

<style scoped lang="scss">
.CreateChallengePopup {
    padding: 1em;
    border: 1px solid rgba(black, 0.1);
    border-radius: 0.5em;
    background-color: white;
}
</style>
