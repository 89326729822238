<template>
  <div class="GroupView">
    <div @click="back()">
      back to overview
    </div>
    <br>
    <GroupPanel
      v-if="group"
      :group="group" />
    <div v-else>
      (Did not find this group)
    </div>

    <ModalContainer
      class="modalContainer"
      name="social-modal" />
  </div>
</template>

<script>
import GroupPanel from '../components/GroupPanel';
import ModalContainer from '@/components/ModalContainer';

export default {
    components: { GroupPanel, ModalContainer },
    props: {
        groupId: {
            type: String,
            required: true
        }
    },
    computed: {
        group() {
            return this.$store.getters['moduleSocial/getGroup'](this.groupId);
        }
    },
    beforeCreate() {
        this.$store.dispatch('moduleSocial/refresh');
    },
    methods: {
        back() {
            this.$router.push('/social/overview');
        }
    }
};
</script>

<style scoped lang="scss">
.GroupView {
    background-color: white;
    height: 100%;
    overflow-y: auto;
    padding: 1em;
}
</style>
