<template>
  <div class="CreateGroupInvitationPopup">
    <div>CreateGroupInvitationPopup</div>
    <br>
    <div>Enter email of friend to add (optional)</div>
    <br>
    <div>
      <input
        v-model.trim="email"
        type="text">
    </div>
    <br>
    <div>
      <Button
        label="Cancel"
        @click="$emit('signal', 'cancel')" />
            &nbsp;
      <Button
        label="Create"
        :disabled="!valid"
        @click="create()" />
    </div>
  </div>
</template>

<script>
import Button from './Button';

export default {
    components: { Button },
    data() {
        return {
            email: '',
            valid: true // false
        };
    },
    methods: {
        create() {
            this.$emit('signal', { email: this.email });
        }
    }
};
</script>

<style scoped lang="scss">
.CreateGroupInvitationPopup {
    padding: 1em;
    border: 1px solid rgba(black, 0.1);
    border-radius: 0.5em;
    background-color: white;
}
</style>
