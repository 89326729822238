<template>
  <div class="SingleGameLevelSettings">
    <div
      v-if="!selectedGameItem"
      class="games">
      <div
        v-for="gameItem of gameItems"
        :key="gameItem.id"
        class="GameItem"
        :style="{ backgroundImage: `url(${gameItem.image})` }"
        @click="pickGame(gameItem)" />
    </div>
    <div v-else>
      <div
        class="GameItem"
        :style="{ backgroundImage: `url(${selectedGameItem.image})` }" />
      <div @click="pickGame(null)">
        select another game
      </div>
      <br>
    </div>

    <div v-if="selectedGameItem">
      Select level: <input
        v-model="levelIndex"
        type="number"
        max="11"
        min="0">
    </div>
  </div>
</template>

<script>
const images = require.context('@/assets/images/memogenius/game/icon/', true, /\.(png|jpg)$/);

export default {
    data() {
        return {
            selectedGameItem: null,
            levelIndex: 0
        };
    },
    computed: {
        gameItems() {
            return this.$store.getters['moduleMemogenius/getGameIds'].map(gameId => {
                return {
                    id: gameId,
                    image: images(`./${gameId}.png`)
                };
            });
        },
        valid() {
            return !!this.selectedGameItem;
        }
    },
    methods: {
        pickGame(gameItem) {
            this.selectedGameItem = gameItem;
        },
        getSettings() {
            return {
                gameId: this.selectedGameItem.id,
                levelIndex: this.levelIndex
            };
        }
    }
};
</script>

<style scoped lang="scss">
.SingleGameLevelSettings {
    padding: 1em;
    border: 1px solid rgba(black, 0.1);
    border-radius: 0.5em;
}
.games {
    display: flex;
    flex-wrap: wrap;
}
.GameItem {
    width: 3em;
    height: 3em;
    // border: 1px solid red;

    margin: 0.5em;

    background-size: contain;
}
</style>
