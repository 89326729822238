<template>
  <div class="MemberItem">
    <div><b>Member</b> {{ member.userId }}</div>
    <!-- <div>userId: {{ member.userId }}</div> -->
    <!-- <div>groupId: {{ member.groupId }}</div> -->
    <br>
    <Button
      v-if="member.userId === $store.state.moduleSocial.userId"
      label="Remove me"
      @click="deleteMember()" />
  </div>
</template>

<script>
import Button from './Button';

export default {
    components: { Button },
    props: {
        member: {
            type: Object,
            required: true
        }
    },
    methods: {
        deleteMember() {
            this.$store.dispatch('moduleSocial/deleteMember', { memberId: this.member.id });
        }
    }
};
</script>

<style scoped lang="scss">
.MemberItem {
    padding: 1em;
    border: 1px solid rgba(black, 0.1);
    border-radius: 0.5em;
}
</style>
