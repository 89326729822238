<template>
  <div class="ChallengeItem">
    <div><b>Challenge</b> {{ challenge.id }}</div>
    <br>
    <div>challengeType: {{ challenge.challengeType }}</div>
    <div>start: {{ challenge.start }}</div>
  </div>
</template>

<script>
export default {
    props: {
        challenge: {
            type: Object,
            required: true
        }
    }
};
</script>

<style scoped lang="scss">
.ChallengeItem {
    padding: 1em;
    border: 1px solid rgba(black, 0.1);
    border-radius: 0.5em;
}
</style>
